import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { BiCategory } from 'react-icons/bi';
import { FiCalendar, FiUser } from 'react-icons/fi';
import { useLocation } from '@reach/router';
import PageSpace from '../components/PageSpace';
import ParagraphText from '../components/typography/ParagraphText';
import { Title } from '../components/typography/Title';
import { SingleBlogStyles } from '../styles/blog/SingleBlogStyles';
import MyPortableText from '../components/MyPortableText';
import SEO from '../components/seo';
import ShareButtons from '../components/ShareButtons';

export const postQuery = graphql`
  query SingleBlogQuery($id: String!) {
    sanityBlog(id: { eq: $id }) {
      title
      publishedAt
      _rawBody
      coverImage {
        asset {
          gatsbyImageData
        }
        alt
      }
      categories {
        title
        slug {
          current
        }
      }
      author {
        name
        slug {
          current
        }
      }
    }
  }
`;

function SingleBlog({ data }) {
  const location = useLocation();

  const blog = data.sanityBlog;
  const urlBlog = location.href;
  const urlBlogSlug = urlBlog?.split('/').pop();
  const url = `https://rakyatkuasa.com/blogs/${urlBlogSlug}`;

  return (
    <SingleBlogStyles>
      <SEO title={blog.title} />
      <PageSpace top={80} bottom={50}>
        <div className='container'>
          <div className='blog-header'>
            <GatsbyImage
              image={blog.coverImage.asset.gatsbyImageData}
              alt={blog.coverImage.alt}
              className='blog-cover-image'
            />
            <Title className='title'>{blog.title}</Title>
            <ParagraphText className='publishedAt'>
              <FiCalendar />
              {format(new Date(blog.publishedAt), 'p, MMMM dd, yyyy')}
            </ParagraphText>
            <ParagraphText className='categoriesText'>
              <BiCategory />
              <span>
                {blog.categories.map((item, index) => (
                  <span key={item.slug.current}>
                    <Link to={`/categories/${item.slug.current}`}>
                      {item.title}
                    </Link>
                    {index < blog.categories.length - 1 ? ', ' : ''}
                  </span>
                ))}
              </span>
            </ParagraphText>
            <ParagraphText className='author'>
              <FiUser />
              <Link to={`/authors/${blog.author.slug.current}`}>
                {blog.author.name}
              </Link>
            </ParagraphText>
          </div>
          <hr className='hr' />
          <div className='body'>
            <MyPortableText value={blog._rawBody} />
          </div>
        </div>
      </PageSpace>

      <div className='share__button'>
        <div className='share__button__title'>Bagikan Halaman ini</div>
        <ShareButtons
          title={blog.title}
          url={url}
          twitterHandle='RakyatKuasa'
          tags={['FPPI', 'FPPINASIONAL', 'RakyatKuasa']}
        />
      </div>
    </SingleBlogStyles>
  );
}

export default SingleBlog;
